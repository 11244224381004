<template>
  <div>
    <br />
    <ImageUpload title="Carregar Logo" container="nixloc-logo-company" :width="300" :height="100" accepted=".jpg .png"
      urlPost="/api/v1/adm/file-upload/upload" urlRemove="/api/v1/adm/file-upload/delete" :onLoad="updateImage"
      v-model="company.logo" />
    <br />
    <Molded>
      <Person :formName="formName" v-model="company.person" />
      <br />
      <Contact :formName="formName" v-model="company.contact" />
      <br />
      <Address :formName="formName" v-model="company.address" />
    </Molded>
    <br />
  </div>
</template>

<script>
import ImageUpload from "@nixweb/nixloc-ui/src/component/forms/ImageUpload";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

import Person from "@nixweb/nixloc-ui/src/component/value-objects/Person.vue";
import Contact from "@nixweb/nixloc-ui/src/component/value-objects/Contact.vue";
import Address from "@nixweb/nixloc-ui/src/component/value-objects/Address.vue";

import Company from "@/components/modules/adm/company/Company.js";

import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "Company",
  components: {
    Molded,
    ImageUpload,
    Contact,
    Person,
    Address,
  },
  data() {
    return {
      formName: "companyUpdate",
      company: new Company(),
      urlUpdate: "/api/v1/adm/company/update",
      urlGetById: "/api/v1/adm/company/get-by-id",
    };
  },
  created() {
    this.getById();
  },
  computed: {
    ...mapState("user", ["userLogged"]),
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["getApi", "putApi"]),
    ...mapMutations("generic", ["addLoading", "removeLoading"]),
    ...mapMutations("validation", ["resetValidation", "removeFormDirty"]),
    getById() {
      let params = {
        url: this.urlGetById,
        obj: { id: this.userLogged.companyId },
      };
      this.getApi(params).then((response) => {
        this.company.logo = response.content.logo;
        this.company.person = response.content.personResponse;
        this.company.contact = response.content.contactResponse;
        this.company.address = response.content.addressResponse;
        let self = this;
        setTimeout(function () {
          self.removeLoading(["panel"]);
          self.removeFormDirty();
        }, 100);
      });
    },
    update() {
      let companyRequest = {
        id: this.userLogged.companyId,
        logo: this.company.logo,
        personRequest: this.company.person,
        contactRequest: this.company.contact,
        addressRequest: {
          zipCode: this.company.address.zipCode,
          street: this.company.address.street,
          number: this.company.address.number,
          complement: this.company.address.complement,
          province: this.company.address.province,
          city: this.company.address.city,
          state: this.company.address.state.content,
        },
      };
      let params = { url: this.urlUpdate, obj: companyRequest };
      this.putApi(params).then((response) => {
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    updateImage() {
      this.update();
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "saveSaveCancel") {
          this.update();
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped></style>
